<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">系统优化意见建议</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item>系统优化意见建议</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">标题:</div>
          <el-input
            v-model="title"
            placeholder="请输入标题"
            clearable
          ></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">申请日期:</div>
          <el-date-picker
            v-model="daterange1"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <button class="btn-pre" @click="getFeedbacks(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-pre btn-add" @click="newBuilt" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          border
          height="500"
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column prop="Title" label="标题" width="80" />
          <el-table-column prop="OS" label="操作系统" width="80" />
          <el-table-column prop="Browser" label="浏览器类型" width="80">
          </el-table-column>
          <el-table-column
            prop="BrowserVersion"
            label="浏览器版本"
            width="80"
          />
          <el-table-column prop="Contents" label="具体描述" width="80" />
          <el-table-column prop="CreateDate" label="申请日期" width="80">
            <template slot-scope="scope">
              {{ scope.row.CreateDate | tableDateFrom }}
            </template>
          </el-table-column>
          <el-table-column prop="Remarks" label="备注" width="80" />
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <i
                class="fa fa-edit"
                v-if="powers.update"
                @click="edit(scope.row)"
              ></i>
              <i
                class="fa fa-trash"
                v-if="powers.delete"
                @click="deletePositions(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getFeedbacks(1)"
          @current-change="getFeedbacks"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="
        dialogType === 1 ? '新建系统优化意见建议' : '编辑系统优化意见建议'
      "
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="from-box2">
            <div class="name">标题:</div>
            <el-input v-model="parameter.Title" placeholder="标题"></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box2">
            <div class="name">操作系统:</div>
            <el-input v-model="parameter.OS" placeholder="操作系统"></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box2">
            <div class="name">浏览器类型:</div>
            <el-input
              v-model="parameter.Browser"
              placeholder="浏览器类型"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box2">
            <div class="name">浏览器版本:</div>
            <el-input
              v-model="parameter.BrowserVersion"
              placeholder="浏览器版本"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box2">
            <div class="name">具体描述:</div>
            <el-input
              type="textarea"
              :rows="2"
              v-model="parameter.Contents"
              placeholder="具体描述"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box2">
            <div class="name">备注:</div>
            <el-input v-model="parameter.Remarks" placeholder="备注"></el-input>
          </div>
        </div>
        <div class="line-box2">
          <div class="text-title">上传附件:</div>
          <div class="upload-box2">
            <div class="left">
              <div class="name" v-if="parameter.UploadingDocument1">
                <div
                  class="name-title"
                  @click="getDownloadUrl(parameter.UploadingDocument1)"
                >
                  {{ parameter.UploadingDocument1 | stringFormater }}
                </div>
                <span v-if="parameter.UploadingDocument1"
                  ><i
                    class="el-icon-delete"
                    @click="parameter.UploadingDocument1 = null"
                  ></i
                ></span>
              </div>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment"
              ref="uploadRef"
            >
              <button class="btn-up">
                <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
              </button>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">取消</el-button
        ><el-button class="pop-save" @click="addUpPositions">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getFeedbacks,
  addFeedback,
  updateFeedback,
  deleteFeedback,
  getFeedbacksItem,
} from "@/api/table";
import { uploadAttachment } from "@/api/user";
export default {
  filters: {
    instruct(data) {
      let end = data.substring(data.indexOf("."), data.length);
      let name = data.substring(data.indexOf("/") + 1, data.indexOf("_"));
      return name + end;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    countryFrom(id, data) {
      if (!data) {
        return;
      }
      const item = data.find((item) => {
        return item.ID === id;
      });

      return item ? item.NameinChinese : null;
    },
    labelFrom(id, data) {
      if (!data) {
        return;
      }
      const item = data.find((item) => {
        return item.value === id;
      });

      return item ? item.label : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      dialogType: 1, //1新建2、编辑
      page: 1,
      size: 10,
      total: null,
      title: null,
      daterange1: null,
      parameter: {
        ID: null,
        Title: null,
        Contents: null,
        OS: null,
        Browser: null,
        BrowserVersion: null,
        UploadingDocument1: null,
        UploadingDocument2: null,
        UploadingDocument3: null,
        UploadingDocument4: null,
        UploadingDocument5: null,
        Remarks: null,
      },
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      loading: true,
      countryList: [],
      name: null,
    };
  },

  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.parameter = {
        ID: null,
        Title: null,
        Contents: null,
        OS: null,
        Browser: null,
        BrowserVersion: null,
        UploadingDocument1: null,
        UploadingDocument2: null,
        UploadingDocument3: null,
        UploadingDocument4: null,
        UploadingDocument5: null,
        Remarks: null,
      };
    },

    newBuilt() {
      this.dialogVisible = true;
      this.dialogType = 1;
    },
    isPureNumber(str) {
      return /^\d+$/.test(str);
    },
    edit(row) {
      this.dialogVisible = true;
      this.dialogType = 2;
      getFeedbacksItem({ id: row.ID }).then((res) => {
        if (res.status === 200) {
          this.parameter = res.response;
        }
      });
    },

    //获取角色列表
    getFeedbacks(type) {
      this.loading = true;
      if (type) {
        this.page = type;
      }

      let applyStartDate = null;
      let applyEndDate = null;

      if (this.daterange1) {
        applyStartDate = this.daterange1[0] ? this.daterange1[0] : null;
        applyEndDate = this.daterange1[1] ? this.daterange1[1] : null;
      }
      const data = {
        page: this.page,
        size: this.size,
        title: this.title,
        applyStartDate,
        applyEndDate,
      };
      getFeedbacks(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
          this.loading = false;
        }
      });
    },
    //添加编辑岗位
    addUpPositions() {
      if (this.dialogType === 1) {
        //新增
        addFeedback(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("创建成功");
            this.getFeedbacks();
            this.handleClose();
          }
        });
      } else {
        updateFeedback(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.getFeedbacks();
            this.handleClose();
          }
        });
      }
    },
    //删除岗位
    deletePositions(row) {
      let data = { IDs: [] };
      data.IDs.push(row.ID);

      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteFeedback(data).then((res) => {
          //
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getFeedbacks();
          }
        });
      });
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },

    //下载附件
    getDownloadUrl(url) {
      location.href = url;
    },

    uploadAttachment(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      if (!suffix1 && !suffix2 && !suffix3 && !suffix4) {
        this.$message.error("只能上传后缀为jpg、png、jpeg、pdf的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name = data.name;
          this.parameter.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
  },

  created() {
    this.getFeedbacks();
    this.getButtonPower();
  },
};
</script>

<style lang="scss" scoped>
.upload-box2 {
  display: flex;
  align-items: center;
  margin-top: 0;
}
.upload-tbox {
  // margin-top: 10px;
  margin-left: 10px;
  i {
    margin-left: 5px;
    cursor: pointer;
  }
  span {
    text-decoration: underline;
    color: #337ab7;
    cursor: pointer;
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 12px;
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        width: 250px;
        margin-right: 24px;
      }
      .el-button {
        margin-left: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 65px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
    }
  }
  .dialog-main {
    border-bottom: 2px solid #e6e6e6;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 340px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
          margin-right: 10px;
        }
        .el-input {
          width: 250px;
        }
        .el-select {
          width: 250px;
        }
      }
      .from-box2 {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 24px;
        .name {
          width: 100px;
          font-size: 16px;
          flex-shrink: 0;
          margin-right: 10px;
          text-align: right;
        }
        .el-input {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
      }
      .single {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        .name {
          flex-shrink: 0;
          width: 80px;
          margin-right: 10px;
        }
        .el-cascader {
          width: 100%;
        }
      }
    }
    .line-box2 {
      display: flex;
      font-size: 16px;
      margin-bottom: 24px;
      align-items: center;
      .text-title {
        width: 100px;
        font-size: 16px;
        flex-shrink: 0;
        margin-right: 10px;
        text-align: right;
      }
      .text-name {
        color: #0076ff;
        cursor: pointer;
      }
    }
  }
  .dialog-footer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.text-box {
  display: flex;
  color: #000000;
  .text-title {
    margin-right: 24px;
  }
  .text-name {
    color: #0076ff;
    cursor: pointer;
  }
}
.upload-box {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.upload-box2 {
  display: flex;
  height: 34px;
  width: 100%;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    height: 100%;
    .name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
      box-sizing: border-box;
      .name-title {
        max-width: 500px;
        overflow: hidden; /* 确保超出容器的内容被裁剪 */
        white-space: nowrap; /* 确保文本在一行内显示 */
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        cursor: pointer;
        color: #315efb;
        margin-left: 10px;
      }
      span {
        color: red;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.upload-box2 {
  display: flex;
  align-items: center;
  margin-top: 0;
}
.dialog-footer2 {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}

.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
</style>
